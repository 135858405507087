<template>
  <div class="specifications__container">
    <div class="goods">
      <div class="goods_name line-2">
        {{ data.commonName }}
      </div>
      <div class="goods_price">
        <span
          style="font-size: 24px; display: inline-block; margin: 0 5px 0 0"
        >{{ data.currencyUnit }}</span>
        <span>{{ (goodsSku && goodsSku.price) || data.buyPriceDesc }}</span>
        <span
          style="
            font-size: 20px;
            display: inline-block;
            margin: 0 5px 0 10px;
            color: #666;
          "
        >{{ data.currencyUnit }}</span>
        <span
          style="font-size: 20px; text-decoration: line-through; color: #666"
        >{{ data.commonPriceDesc }}</span>
      </div>

      <div class="goods__format">
        <div v-for="(item, index) in formatData.skuNameVOList" :key="item.skuName + '' + index" class="format__list">
          <div class="name">{{ item.skuName }}</div>
          <ul class="value">
            <li
              v-for="(inner, innerIndex) in item.skuValues"
              :key="inner + '' + innerIndex"
              :class="{ active: selectedSkuList.includes(inner) }"
              @click="onClickSkuItemBtn(inner, index)"
            >
              <a href="javascript:void(0)">{{ inner }}</a>

              <i v-if="selectedSkuList.includes(inner)" class="icon el-icon-check" />
            </li>
          </ul>
        </div>
      </div>

      <div class="goods__count-wrap">
        <div class="goods_cont">
          <div class="label">{{ this.$t('common.number') }}</div>
          <el-input-number
            v-model="count"
            :disabled="!goodsSku"
            class="cont_number"
            :min="1"
            :precision="0"
            :max="goodsSku && goodsSku.remainStock"
            size="mini"
          />
        </div>

        <div class="goods_cont">
          <div class="label" style="margin-right: 12px">{{ this.$t('common.stock') }}</div>
          <div class="count">{{ goodsSku ? goodsSku.remainStock : data.qty }}</div>
        </div>
      </div>

      <div class="goods__count-wrap list">
        <div class="goods_cont small">
          <div class="label">{{ this.$t('common.ShippingAddress') }}</div>
          <div class="count">{{ data.address }}</div>
        </div>

        <div class="goods_cont">
          <div class="label small">{{ this.$t('common.Shippers') }}</div>
          <div class="count">
            <a v-if="!isIsolate" class="link" :href="'/goodsAll/list?domain_prefix=' + data.domainPrefix" target="_blank">{{ data.storeName }}</a>
            <template v-else>{{ data.storeName }}</template>
          </div>
        </div>

        <div class="goods_cont small">
          <div class="label">{{ this.$t('common.ShippingInformation') }}</div>
          <div class="count">
            <el-dropdown>
              <span class="freight-cost">{{ this.$t('common.Viewfreight') }}</span>

              <el-dropdown-menu slot="dropdown" class="special-dropdown">
                <el-dropdown-item v-for="item in (data.freightInfoList || [])" :key="item.id">
                  <div class="fregith-cost__item">
                    <div class="country">{{ item.countryName }}</div>
                    <div
                      class="currency"
                    >
                      <span
                        v-for="inner in item.freightInfo"
                        :key="inner.id"
                        class="currency-inner"
                      >
                        {{ inner.currencyUnit }} {{ inner.freightBig }}
                      </span>
                    </div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>

      <!-- <div class="freight">
        <div style="margin-right: 23px; flex-shrink: 0;">原产地</div>
        <div>
          {{ data.goodsLocation }}
        </div>
      </div> -->

      <!-- <div class="freight" style="margin-top: 15px">
        <div style="margin-right: 34px; flex-shrink: 0;">配送</div>
        <div>由</div>
        <div style="margin: 0 7px; color: #222222">{{ data.tradeMode === 1 ? data.goodsLocation : data.bondedWarehouse }}</div>
        <div style="flex-shrink: 0;">邮寄至</div>
        <div style="margin: 0 7px; color: #222222">{{ data.address }}</div>
      </div> -->

      <!-- <div class="freight" style="margin-top: 15px">
        <div style="margin-right: 34px; flex-shrink: 0;">运费</div>
        <div>¥{{ data.freightPrice }}</div>
      </div>

      <div class="freight" style="margin-top: 15px">
        <div style="margin-right: 34px">税费</div>
        <div>¥{{ data.taxation }}</div>
      </div> -->

      <div class="goods__btn-group">
        <div class="group">
          <!-- 立即购买按钮 -->
          <el-button
            class="button"
            :disabled="(disabled) || !goodsSku || goodsSku.remainStock === 0"
            @click="onClickOperateBtn(2)"
          >
            {{ this.$t('common.Buynow') }}
          </el-button>
          <!-- 加购按钮 -->
          <el-button
            class="button cart"
            :disabled="(disabled) || !goodsSku || goodsSku.remainStock === 0"
            @click="onClickOperateBtn(1)"
          >
            {{ this.$t('common.Addshoppingcart') }}
          </el-button>
        </div>

        <div v-if="isIsolate" class="full">
          <a :href="protocolName + '//' + indexHost + '/goods/detail?id=' + data.goodsId">
            <el-button class="button">{{ this.$t('common.go') }} Wharfon {{ this.$t('common.buy') }}</el-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
import { Message } from 'element-ui'

import { getDomainPrefix } from '@/utils'
import { useRequest } from '@/utils/request'

import session from '@/utils/session.js'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    formatData: {
      type: Object,
      default: () => ({
        skuNameVOList: [],
        skuVOList: []
      })
    },

    disabled: {
      type: Boolean,
      default: true
    }
  },

  setup(props, { root, nextTick }) {
    const set = root.$set
    const router = root.$router
    const $t = root.$jst

    const data = reactive({
      selectedSkuList: [],
      count: 1,
      redirectQuery: computed(() => root.$store.state.app.redirectQuery),

      callbackName: root.$store.state.app.redirectQuery.callback_name,
      countryId: computed(() => root.$store.state.app.countryItem?.id),
      currencyId: computed(() => root.$store.state.app.currencyItem?.id),

      indexHost: 'www.' + process.env.VUE_APP_HOST_URL,

      isIsolate: computed(() => getDomainPrefix !== ''),

      protocolName: window.location.protocol
    })

    watch(() => props.formatData.skuNameVOList, res => {
      if (!res) return
      data.selectedSkuList = (props.formatData.skuNameVOList || []).map(current => {
        return current.skuValues[0]
      })
    })

    const onClickSkuItemBtn = (item, level) => {
      // 实现反选操作
      if (data.selectedSkuList[level] === item) {
        set(data.selectedSkuList, level, '')
        return
      }

      set(data.selectedSkuList, level, item)
    }

    const goodsSku = computed(() => {
      const filteredSkuList = data.selectedSkuList.filter(current => current)

      // 判断已选择的值的长度 等于 规格的长度，表示所有规格里都有一个属性被选择了
      if (filteredSkuList.length === props?.formatData?.skuNameVOList?.length) {
        if (!props?.formatData?.skuVOList) return

        const found = props.formatData.skuVOList.find(current => {
          return filteredSkuList.every(inner => current.skuName.indexOf(inner) > -1)
        })

        return found
      }

      return null
    })

    watch(() => goodsSku.value, goodsSku => {
      // 切换时将最大库存进行修改
      if (goodsSku) {
        if (goodsSku.remainStock < data.count) {
          data.count = goodsSku.remainStock
        }
      }
    })

    const toSendNormalData = computed(() => ({
      goodsId: props.data.goodsId,
      count: data.count,
      domainPrefix: getDomainPrefix,
      skuId: goodsSku.value.skuId,
      countryId: data.countryId,
      currencyId: data.currencyId
    }))

    const toSendData = computed({
      get() {
        if (data.redirectQuery.callback_name) {
          // const route = root.$route.query
          return {
            goodsId: data.redirectQuery.goodsId,
            count: data.redirectQuery.count,
            domainPrefix: data.redirectQuery.domainPrefix,
            skuId: data.redirectQuery.skuId,
            countryId: data.countryId,
            currencyId: data.currencyId
          }
        } else {
          return toSendNormalData
        }
      }
    })

    const { fetch: handleAddCartAction, data: addCartResult } = useRequest('goods/handleAddCartAction', {
      data: toSendData.value,
      immediate: false
    })

    // const { fetch: handlePurchaseAction, data: purchaseResult } = useRequest('goods/handlePurchaseAction', {
    //   data: toSendData,
    //   immediate: false
    // })

    const onClickOperateBtn = type => {
      // const isLogin = store.getters['user/isLogin']
      // if (!isLogin) {
      //   sessionStorage.redirect = route.fullPath
      //   router.push({ name: 'User' })
      //   return
      // }

      if ((goodsSku && goodsSku.remainStock) < data.count) {
        return Message.error($t('common.Insufficientinventorygoods'))
      }

      if (!data.count) {
        return Message.error($t('common.Pleasebuyleastoneitem'))
      }
      // TODO: 这里补充判断
      if (props.data.goodsStatus !== 1) {
        console.log(props.data.goodsStatus, '商品状态')
        return Message.error($t('common.buyAndaddCart'))
      }
      if (type === 1) {
        handleAddCartAction()
      }

      if (type === 2) {
        const goods = {
          quickBuySkuId: goodsSku.value.skuId,
          quickBuyCount: data.count
        }
        session.set('goods', goods)
        router.push({ name: 'OrderSubmit', query: { quick: '1' }})
        // handlePurchaseAction()
      }
    }

    watch(() => addCartResult.value, result => {
      if (result) {
        Message.success($t('common.Addedsuccessfully'))
      }
    })

    // watch(() => purchaseResult.value, result => {
    //   if (result) {
    //        router.push({ name: 'OrderSubmit', query: { quick: '1' }})
    //   }
    // })

    onMounted(() => {
      switch (data.callbackName) {
        case 'goods/handleAddCartAction':
          handleAddCartAction()

          root.$nextTick(() => {
            root.$store.commit('app/SET_REDIRECT_QUERY', {})
          })
          break
      }
    })

    return {
      ...toRefs(data),
      goodsSku,

      onClickOperateBtn,
      onClickSkuItemBtn
    }
  }
}
</script>
<style lang="scss" scoped>
.specifications__container {
  width: 408px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  padding-top: 35px;
  border-top: 1px solid #0D5CAB;
  margin-left: 99px;

  .goods {
    padding: 6px 0 0;
    // padding-bottom: 20px;
    .goods_name {
      width: 90%;
      font-size: 24px;
      line-height: 38px;
      font-weight: bolder;
      color: #2b2b2b;
    }

    .goods_price {
      padding: 22px 0;
      border-bottom: 1px solid #EEEEEE;
      font-size: 24px;
      font-weight: 400;
      color: #ff2900;

    }

    .freight {
      display: flex;
      color: #666666;
      font-size: 12px;
    }
  }

  .goods__count-wrap {

    border-bottom: 1px solid #eee;

    &.list {
      padding: 33px 0 36px;

      .goods_cont {
        padding: 0;
        margin-bottom: 29px;

        &.small {
          .label {
            font-size: 14px;
          }
        }

         &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .goods_cont {
    color: #666666;
    font-size: 14px;
    padding: 32px 0;
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .count {
      font-size: 14px;
      // margin-left: 23px;
      color: #222222;

      .link {
        text-decoration: underline;
        color: #0049AC;
      }
    }

    .label {
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
  }

  .goods_btn {
    margin-left: 10px;
  }
}

.goods__format {
  padding: 24px 0 50px;
  border-bottom: 1px solid #eee;

  .format__list {
    display: flex;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 12px;
    }

    .name {
      min-width: 66px;
      flex-shrink: 0;
      margin-right: 30px;
      font-size: 16px;
      line-height: 36px;
      font-weight: 500;
      color: #222222;
    }

    .value {
      @include clearfix;

      li {
        position: relative;
        float: left;
        border: 1px solid #D6D6D6;
        border-radius: 4px;
        margin-right: 6px;
        margin-bottom: 10px;
        line-height: 36px;
        font-size: 12px;

        a {

          display: block;
          padding: 0 13px;
          color: #666666;
        }

        &.active {
          border-color: #0049AC;
          a {
            color: #0049AC;
          }

          .icon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 16px;
            height: 16px;
            padding-top: 5px;
            line-height: 16px;
            text-align: right;
            background: linear-gradient(135deg, transparent 0, transparent 50%, #0049AC 50%);
            color: #fff;
            z-index: 1;
          }

        }
      }
    }
  }
}

.freight-cost {
  font-size: 14px;
  cursor: pointer;
  color: #0049AC;
}

.fregith-cost__item {
  display: flex;
  justify-content: space-between;
  width: 250px;

  span {
    font-size: 14px;
    color: #222;
  }

  .currency-inner {
    display: block;
  }
}

.goods__btn-group {
  padding: 32px 0 32px 28px;

  .button {
    width: 185px;
    height: 50px;
    border: 1px solid #0049AC;
    border-radius: 25px;
    // line-height: 50px;
    background: #0049AC;
    box-shadow: 0px 16px 40px 6px rgba(0, 73, 172, 0.17);
    color: #fff;

    &:hover {
      border-color: #6AABED;
      background: #6AABED;
      box-shadow: 0px 16px 40px 6px rgba(0, 73, 172, 0.31);
    }

    &.cart {
      border-width: 2px;
      color: #0049AC;
      background: #fff;
      box-shadow: none;

      &:hover {
        border-color: #6AABED;
        background: #6AABED;
        box-shadow: 0px 16px 40px 6px rgba(0, 73, 172, 0.31);
        color: #fff;
      }
    }
  }

  .group {

  }

  .full {
    margin-top: 36px;
    .button {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.cont_number {
  width: 90px;
  border: 1px solid #eee;
  border-radius: 20px;
  line-height: 40px;
  overflow: hidden;

  .el-input {

    .el-input__inner {
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      border: none;
    }
  }

  .el-input-number__decrease {
    border-radius: 0;
    border: none;
    font-weight: bolder;
    background: #fff;
    color: #AEAFB6;
  }
  .el-input-number__increase {
    border-radius: 0;
    border: none;
    font-weight: bolder;
    background: #fff;
    color: #AEAFB6;
  }
}

.special-dropdown {
  max-height: 300px;
  overflow: auto;
}

</style>
