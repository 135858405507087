<template>
  <div class="goodsIng__component">
    <div class="goods__carousel-wrap">
      <el-carousel
        ref="carousel"
        class="goods_carousel"
        height="480px"
        trigger="click"
        direction="horizontal"
        indicator-position="none"
        :interval="5000"
        @change="carouselChange"
      >
        <el-carousel-item v-for="(item, index) in data.goodsPictureList" :key="index">
          <el-image
            v-if="item.fileType === 1"
            fit="fill"
            style="width: 100%; height: 100%"
            :src="item.pictureUrl"
          />

          <video v-if="item.fileType === 2" controls style="width: 100%; height: 100%">
            <source :src="item.pictureUrl">
          </video>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="indicator">
      <div
        v-for="(item, index) in data.goodsPictureList"
        :key="index"
        :class="initialIndex === index ? 'indicator_item_active' : ''"
        class="indicator_item"
        @click="clickImg(index)"
      >
        <el-image
          v-if="item.fileType === 1"
          fit="fill"
          style="width: 100%; height: 100%"
          :src="item.pictureUrl"
        />

        <video v-if="item.fileType === 2" style="width: 100%; height: 100%;">
          <source :src="item.pictureUrl">
        </video>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from '@vue/composition-api'
export default {
  name: 'GoodsImage',

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  setup() {
    const carousel = ref(null)

    const data = reactive({
      initialIndex: 0
    })

    const clickImg = index => {
      carousel.value.setActiveItem(index)
    }

    const carouselChange = index => {
      data.initialIndex = index
    }

    return {
      ...toRefs(data),

      carousel,
      clickImg,
      carouselChange
    }
  }
}
</script>
<style lang="scss" scoped>
.goodsIng__component {
  // display: flex;
  flex-shrink: 0;

  .goods__carousel-wrap {
    width: 600px;
    height: 660px;
    padding: 90px 60px;
    box-shadow: 0px 16px 61px 1px rgba(232, 232, 234, 0.88);
    background: #fff;
  }

  .indicator {
    width: 600px;
    margin-top: 84px;
    margin-right: 22px;

    @include clearfix;

    .indicator_item:first-child {
      margin-top: 0;
    }
    .indicator_item {
      float: left;
      width: 128px;
      height: 128px;
      padding: 10px;
      margin-right: 22px;
      margin-bottom: 22px;
      background: #f7f7f7;
      box-sizing: border-box;
    }
    .indicator_item_active {
      outline: 2px solid $themeColor;
    }
  }
  .goods_carousel {
    width: 480px;
    height: 480px;
    background: #f7f7f7;
  }
}
</style>
