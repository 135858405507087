<!--
 * @Author: Neko
 * @Date: 2021-01-06 14:05:44
 * @LastEditTime: 2021-03-25 15:21:13
 * @LastEditors: Neko
-->
<template>
  <div class="goodsDetails__container">
    <div class="goods__wrap">
      <div class="goods__view">
        <GoodsImg :data="goodsData" />
        <Specifications :data="goodsData" :disabled="isDisabledClick" :format-data="formatData" />

        <!-- <i class="collection el-icon-star-on" :class="{ active: goodsData.isGoodsColletc }" @click="onClickCollectionBtn" /> -->
      </div>
    </div>

    <div class="goods__tabs">
      <div class="goods__tabs-wrap">
        <el-tabs v-model="active">
          <el-tab-pane :label="$t('goodsDetails.Productdetails')" name="1">
            <div class="goods__detail">
              <div v-html="goodsData.goodsDesc" />
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('goodsDetails.Productspecifications')" name="2">
            <div class="goods_info">
              <div class="title">{{ $t('goodsDetails.basicinformation') }}</div>
              <div class="info">
                <dl v-for="item in attributeList" :key="item.id" class="info_item">
                  <dt class="name">{{ item.attribute }}：</dt>
                  <dd class="value">{{ item.attributeValue }}</dd>
                </dl>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

  </div>
</template>
<script>
import { reactive, toRef, toRefs, computed, watch, onMounted } from '@vue/composition-api'
import { Base64 } from 'js-base64'
import { Message } from 'element-ui'

import GoodsImg from '@/components/Goods/goodsImg'
import Specifications from '@/components/Goods/specifications'

import { useRequest } from '@/utils/request'

export default {
  name: 'GoodsDetails',
  components: {
    GoodsImg,
    Specifications
  },

  setup(_, { root }) {
    const route = root.$route
    const router = root.$router
    const store = root.$store

    const data = reactive({
      id: route.query.id,

      goodsData: {},
      formatData: {},

      active: '1',

      isDisabledClick: true,

      currencyId: computed(() => store.state.app.currencyItem?.id),
      countryId: computed(() => store.state.app.countryItem?.id)
    })

    const { data: goodsData, fetch: getGoodsDetailFetch } = useRequest('goods/getGoodsDetail', {
      data: computed(() => ({
        goodsId: data.id,
        countryId: data.countryId,
        currencyId: data.currencyId
      })),

      initialData: toRef(data, 'goodsData'),

      immediate: false,

      onSuccess(res) {
        if (res.code === 200) {
          data.isDisabledClick = false
        }
      },

      onFail(res) {
        data.isDisabledClick = true
        Message.error(res.message)
      }
    })

    watch(() => goodsData.value, res => {
      data.goodsData.goodsDesc = Base64.decode(data.goodsData.goodsDesc)
    })

    watch(() => data.countryId, () => {
      if (data.countryId && data.currencyId) {
        getGoodsDetailFetch()
      }
    })

    watch(() => data.currencyId, () => {
      if (data.countryId && data.currencyId) {
        getGoodsDetailFetch()
      }
    })

    const attributePropertyList = ['goodsBaseAttributeList', 'goodsSupplementAttributeList', 'requiredGoodsBaseAttributeList', 'requiredGoodsSupplementAttributeList']

    const attributeList = computed(() => {
      return attributePropertyList.reduce((prev, current) => {
        if (data.goodsData.attributeAddDTO) {
          prev.push(...data.goodsData.attributeAddDTO[current])
        }

        return prev
      }, [])
    })

    const { fetch } = useRequest('goods/getGoodsFormat', {
      data: computed(() => ({
        goodsId: data.id,
        goodsType: data.goodsData.goodsType,
        countryId: data.countryId,
        currencyId: data.currencyId
      })),

      initialData: toRef(data, 'formatData'),

      immediate: false
    })

    const { fetch: handleGoodsCollectFetch } = useRequest('goods/handleGoodsCollectAction', {
      data: {
        goodsId: data.id
      },

      immediate: false,
      onSuccess() {
        data.goodsData.isGoodsColletc = (data.goodsData.isGoodsColletc === 0 ? 1 : 0)
      }
    })

    const onClickCollectionBtn = _ => {
      const isLogin = store.getters['user/isLogin']
      if (!isLogin) {
        sessionStorage.redirect = route.fullPath
        router.push({ name: 'User' })
        return
      }
      handleGoodsCollectFetch()
    }

    watch(() => data.goodsData, () => {
      fetch()
    })

    onMounted(() => {
      if (data.countryId && data.currencyId) {
        getGoodsDetailFetch()
      }
    })

    return {
      ...toRefs(data),

      attributeList,
      onClickCollectionBtn
    }
  }
}
</script>
<style lang="scss" scoped>
.goodsDetails__container {
  padding-bottom: 45px;

  .goods__wrap {
    min-width: 1200px;
    width: 96.9%;
    max-width: 1600px;
    margin: 0 auto;
    background: #fff;
  }

  .goods__view {
    position: relative;
    width: 1200px;
    margin: 24px auto 0;
    padding: 80px 0 114px 73px;
    display: flex;
    justify-content: space-between;

    .collection {
      position: absolute;
      top: 126px;
      right: 0;
      font-size: 24px;
      color: #222;
      cursor: pointer;

      &.active {
        color:#F56C6C;
      }
    }
  }
  .goods_info {
    width: 1200px;
    margin: 8px auto 96px;
    // background-color: #fafafa;
    // padding: 20px;
    // margin: 24px auto 0;
    .title {
      padding-bottom: 14px;
      font-size: 20px;
      font-weight: bolder;
      color: #0049AC;
    }
    .info {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1200px;
      border-width: 1px;
      border-style: solid;
      border-color: #0049AC transparent #EEEEEE #EEEEEE;
      border-right: none;
      border-bottom: none;
      // border-top: 1px solid #0049AC;
      // border-left: 1px solid #EEEEEE;
      // border-right: 1px solid #EEEEEE;

      .info_item {
        font-size: 14px;
        width: 598px;
        display: flex;
        flex-shrink: 0;

        // margin: 6px 0;
        .name {
          width: 299px;
          padding: 0 20px;
          border-right: 1px solid #eee;
          border-bottom: 1px solid #eee;
          line-height: 50px;
          flex-shrink: 0;
          color: #2b2b2b;
        }
        .value {
          width: 299px;
          padding: 0 20px;
          border-right: 1px solid #eee;
          border-bottom: 1px solid #eee;
          line-height: 50px;
          flex-shrink: 0;
          background: #F8F9FA;
          color: #666666;
        }
      }
    }
  }

  .goods__detail {
    width: 1200px;
    margin: 0 auto 96px;

    ::v-deep img {
      max-width: 960px!important;
      width: 100%;

    }
  }

  .goods__tabs {
    min-width: 1200px;
    width: 96.9%;
    max-width: 1600px;
    margin: 0 auto 96px;
    background: #fff;

    .goods__tabs-wrap {
      // width: 1200px;
      // margin: 0 auto;

      ::v-deep .el-tabs__header {
        // height: 78px;
        // padding-top: 4px;
        border: 1px solid #eee;
        border-left: none;
        border-right: none;

        .el-tabs__nav-wrap {
          width: 1200px;
          margin: 0 auto;
          line-height: 35px;
        }
      }

      ::v-deep .el-tabs__item {
        // padding: 33px 0 27px 0;
      }

      ::v-deep .el-tabs__nav-wrap {
        &::after {
          background: #fff;
        }
      }

      ::v-deep .el-tabs__nav {
        padding: 27px 0 20px;
      }

      ::v-deep .el-tabs__active-bar {
        height: 3px;
      }
    }
  }
}
</style>
